<template>
  <!-- xtEn6GvvcmZr9d3LxskWMSbaldanClientQuizID00 Baldan Client Quiz Key -->
  <!-- edodDrpzSDYRKMvgYiYVMS138600 Elth Client Quiz Key -->
  <div class="position-relative">
    <div v-if="showAlert" class="AlertContainer">
      <div
        class="alert text-center fade show text-white alert-text"
        role="alert"
        style="background: #f27367; width: fit-content">
        <span
          ><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
          {{ alertMsg }}
        </span>
      </div>
    </div>
    <!--  -->
    <div v-if="isResponse">
      <div class="" v-if="collectionLink">
        <BaldanEndPage :collectionLink="collectionLink" :quizId="quizId" />
      </div>
      <div
        v-else-if="collectionLink || false"
        class="mt-5 w-100 h-100 d-flex flex-column justify-content-between align-items-center">
        <div class="mb-4">
          <LazyImage
            :src="`https://images.quizell.com/website/Frame%20(6)%20(1).png`"
            style="width: 100%; height: 200px"
            :alt="`Quizell Product Quiz`"
            :title="`Quizell Product Quiz`"
            class="rounded successImage" />
        </div>

        <p
          style="
            font-family: 'montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            color: #503a3c;
          ">
          {{ translateCustomText("YAYY!") }}
        </p>
        <div class="w-md-75 mx-4">
          <p
            class=""
            style="
              font-family: 'montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              color: rgb(255, 102, 58); ;
            ">
            {{
              translateCustomText(
                "...your new shop page is ready! Click the button below to view it."
              )
            }}
            <!-- ...your new shop page is ready! Click the button below to view it. -->
          </p>
        </div>
        <!-- quizId == baldanClientQuizID -->
        <button
          v-if="allowedQuizID.includes(quizId)"
          style="
            width: 204px;
            height: 44px;
            background: #a9411f;
            border: 1px solid #a9411f;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 52px;
          "
          @click="openSubscriptionQuiz()">
          <span
            style="
              font-family: 'montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
            ">
            {{ translateCustomText("Pay Now") }}
          </span>
        </button>
        <button
          v-else
          style="
            width: 204px;
            height: 44px;
            background: #a9411f;
            border: 1px solid #a9411f;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 52px;
          "
          @click="openExternalLink(collectionLink)">
          <span
            style="
              font-family: 'montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
            ">
            {{ translateCustomText("View page") }}
          </span>
        </button>
        <div
          style="text-align: center"
          class="mt-3"
          v-if="allowedQuizID.includes(quizId)">
          <button
            @click="openCurrentLink(collectionLink)"
            style="
              width: 204px;
              height: 44px;
              background: transparent;
              border: 2px solid #9c4629;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 52px;
            ">
            <span
              style="
                font-family: 'montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #a9411f;
              ">
              {{ translateCustomText("View shop ") }}
            </span>
          </button>
        </div>
        <div style="text-align: center" class="mt-3" v-else>
          <button
            @click="restartQuiz"
            style="
              width: 204px;
              height: 44px;
              background: transparent;
              border: 2px solid #9c4629;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 52px;
            ">
            <span
              style="
                font-family: 'montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #a9411f;
              ">
              {{ translateCustomText("Restart") }}
            </span>
          </button>
        </div>

        <div
          style="text-align: center"
          class="mt-3"
          v-if="!allowedQuizID.includes(quizId)">
          <button
            class="btn"
            style="
              font-family: 'montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              color: #ff663a;
              cursor: pointer;
            "
            @click="copyLink(collectionLink)">
            {{ translateCustomText("Copy shop link") }}
          </button>
        </div>
      </div>
      <div v-else>
        <div
          v-if="errorMsgType == 'affiliate_error'"
          class="w-100 mt-5 h-100 d-flex flex-column justify-content-between align-items-center">
          <div class="mb-4">
            <LazyImage
              :src="`https://images.quizell.com/website/Elth-affiliate-error.png`"
              style="width: 100%; height: 350px"
              :alt="`Quizell Product Quiz`"
              :title="`Quizell Product Quiz`"
              class="rounded" />
          </div>
          <p
            style="
              font-family: 'montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 27px;
              text-align: center;
              color: #503a3c;
            ">
            Oops!
          </p>
          <p
            style="
              font-family: 'montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              color: #ff663a;
            ">
            {{
              translateCustomText(
                "Hi Elth user! We noticed that you are already have a shop. Now choose if you want to..."
              )
            }}
          </p>

          <button
            style="
              width: 204px;
              height: 44px;
              background: #a9411f;
              border: 1px solid #a9411f;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              border-radius: 52px;
            "
            @click="
              openExternalLink(
                'https://elth.co.uk/pages/join-us#shopify-section-template--16703907528929__b67d8ef8-2a80-4684-838b-e59cc5c9dff0'
              )
            ">
            <span
              style="
                font-family: 'montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #ffffff;
              ">
              Sign up
            </span>
          </button>
          <div style="text-align: center" class="mt-3">
            <button
              @click="restartQuiz"
              style="
                width: 204px;
                height: 44px;
                background: transparent;
                border: 2px solid #9c4629;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 52px;
              ">
              <span
                style="
                  font-family: 'montserrat';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: #a9411f;
                ">
                {{ translateCustomText("Restart") }}
              </span>
            </button>
          </div>
        </div>
        <div
          v-else
          class="w-100 h-100 d-flex flex-column justify-content-between align-items-center">
          <p
            :style="getTitleStyles(ProductStyles)"
            style="font-size: 30px !important">
            Opps Something went wrong!!
          </p>
          <p
            :style="getTitleStyles(ProductStyles)"
            style="font-size: 20px !important"
            v-if="Array.isArray(errorMsg)">
            {{ errorMsg[0] }}
          </p>
          <p
            :style="getTitleStyles(ProductStyles)"
            style="font-size: 20px !important"
            v-else>
            {{ errorMsg }}
          </p>
          <button :style="getButtonCss(buttonStyle)" @click="CloseErrorModal">
            <span
              :style="{
                opacity: `${buttonStyle.textOpacity}%`,
                fontSize: `${buttonStyle.fontSize}px`,
              }">
              {{ translateCustomText("Go Back") }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-else  style=" height: 100%">
      <!-- Step 1 start -->
      <div v-if="!isStep2" class="">
        <BaldenStep1
          :isCreate="true"
          :isDesktopView="isDesktopView"
          :quizId="quizId"
          :isAddNew="true"
          @moveToStep2="isStep2 = true"
          @updateTextDesc="updateTexTDescription" />
      </div>
      <!-- Step 1 End -->

      <!-- Step 2 Start -->
      <div v-else class="d-flex flex-column">
        <div class="px-1 py-0 px-md-5 py-md-3">
          <!-- <div v-if="isLoadingProduct" style="min-height: 500px">
            <LoaderMain />
          </div> -->
          <BaldanTable
             @isNotRemoveProducts="isNotRemoveProducts"
            @payload="(pay) => (payload = pay)"
            @loaded="handleBaldanTableLoaded"
            :isCreate="true"
            :options="getOptions"
            :email="getSavedLeadID.email"
            :apiToken="apiToken"
            :selectedProducts="selectedProducts"
            :products="products"
            @update="getSelectProduct"
            :quizId="quizId"></BaldanTable>

          <div class="d-flex  mt-3 justify-content-center">
             <ElthButton @click="submitData()" :isLoading="submitSpinner" style="order: 1px solid rgb(80, 58, 60);
    background: linear-gradient(rgb(255, 218, 216) 0%, rgb(255, 162, 130) 99%);
    color: rgb(80, 58, 60);" >

              
                {{ translateCustomText("Create your shop") }}
    </ElthButton>
 
          </div>
        </div>
        <div
          v-if="allowedQuizID.includes(quizId) && submitSpinner"
          class="d-flex justify-content-center mt-2"
          :style="{
            fontSize: '15px',
            'font-weight': headingStyle.fontWeight,
            margin: `${headingStyle.marginTop}px ${headingStyle.marginRight}px ${headingStyle.marginBottom}px ${headingStyle.marginLeft}px`,
            lineHeight: headingStyle.lineHeight,
            textAlign: headingStyle.textAlign,
            opacity: headingStyle.opacity,
            color: headingStyle.color,
            padding: `${headingStyle.paddingTop}px ${headingStyle.paddingRight}px ${headingStyle.paddingBottom}px ${headingStyle.paddingLeft}px`,
          }">
          <p class="m-0">
            {{
              translateCustomText(
                `The store creation process may take a few minutes, please do not close this window to avoid losing data.`
              )
            }}
          </p>
        </div>
      </div>
      <!-- Step2 End -->
    </div>
  </div>
</template>

<script>
import ElthButton from './ElthButton.vue'
import axios from "axios";
import cssFunctionMixin from "../../../customize/mixin/cssFunction.js";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [cssFunctionMixin, TranslationMixin],
  props: [
    "questionAnswer",
    "Products",
    "ProductStyles",
    "apiToken",
    "pageHeight",
    "quizId",
    "headingStyle",
    "isDesktopView",
  ],
  components: {
    // LoaderMain: () =>
    //   import(
    //     /* webpackChunkName: "LoaderMain" */ "../../../../components/LoaderComps/LoaderMain.vue"
    //   ),
    ElthButton,
    BaldenStep1: () =>
      import(/* webpackChunkName: "BaldenStep1" */ "./BaldenUpdateStep2.vue"),
    BaldanTable: () =>
      import(/* webpackChunkName: "BaldanTable" */ "./Table.vue"),
    BaldanEndPage: () =>
      import(/* webpackChunkName: "BaldanEndPage" */ "./BaldanEndPage.vue"),
  },

  data() {
    return {
       isNotRemoveSelectAllArray:false,
      notRemoveSelectAllArray:[],
      isStep2: false,
      payload: {},
      productType: [],
      productVendors: [],
      selectedVendor: "",
      selectedType: "",
      style: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        textOpacity: 100,
        color: "#9C4629",
        textDecoration: "unset",
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "left",
        fontSize: 14,
        fontFamily: "Poppins",
        strokeOpacity: 100,
        strokeColor: "#9C4629",
        strokeRotation: 0,
        borderSize: 3,
        borderRadius: 0,
        borderType: "border-bottom",
        sendBtnFontWeight: "normal",
        sendBtnFontFamily: "Poppins",
        sendBtnFontSize: 14,
        sendBtnFontStyle: "normal",
        sendBtnTextDecoration: "unset",
        sendBtnBGColor: "#000000",
        sendBtnColor: "#ffffff",
        sendBtnWidth: 150,
        sendBtnHeight: 45,
      },
      buttonStyle: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
        paddingBottom: 15,
        backgroundColor: "#9C4629",
        color: "#ffffff",
        textAlign: "center",
        fontWeight: "bold",
        fontStyle: "normal",
        buttonWidth: 150,
        buttonHeight: "42",
        widthIsAuto: true,
        borderRadius: "40",
        borderSize: "0",
        borderColor: "",
        position: "center",
        textOpacity: 100,
        backgroundOpacity: 100,
        fontSize: "16",
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 22,
        marginRight: 10,
        lineHeight: 1,
        textDecoration: "unset",
        fontFamily: "Poppins",
      },
      createButtonStyle: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "#9C4629",
        color: "#ffffff",
        textAlign: "center",
        fontWeight: "bold",
        fontStyle: "normal",
        buttonWidth: 200,
        buttonHeight: "42",
        widthIsAuto: true,
        borderRadius: "40",
        borderSize: "0",
        borderColor: "",
        position: "center",
        textOpacity: 100,
        backgroundOpacity: 100,
        fontSize: "16",
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 22,
        marginRight: 10,
        lineHeight: 1,
        textDecoration: "unset",
        fontFamily: "Poppins",
      },
      products: [],
      title: "",
      filter_text: "",

      description: "",
      selectedProducts: [],
      selectAllProducts: false,
      submitSpinner: false,
      isResponse: false,
      errorMsgType: null,
      collectionLink: null,
      errorMsg: null,
      SuccessMsg: "Congratulations! Your collection has been created.",
      showAlert: false,
      alertMsg: "",
      isLoadingProduct: true,
    };
  },
  watch: {
    Products: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.products = newValue;
      },
    },
    description(newVal, oldVal) {
      if (newVal.length > 400) {
        this.description = oldVal;
      }
    },
    title(newVal, oldVal) {
      if (newVal.length > 30) {
        this.title = oldVal;
      }
    },
    // selectedType(newVal,oldVal){
    //   if(newVal && newVal != oldVal){
    //       this.selectAllProducts = false;
    //   }

    // }
  },

  methods: {
    handleBaldanTableLoaded() {
      this.isLoadingProduct = false;
    },
    getSelectProduct(e) {
      this.selectedProducts = e;
    },
    resetFilter() {
      // this.selectAllProducts = false;
      this.selectedVendor = "";
      this.selectedType = "";
      this.filter_text = "";
    },
    openSubscriptionQuiz() {
      window.open("/quiz/wzj9TeoWwbacxXvWSamEMS249000", "_self");
    },
    translateCustomText(key) {
      return this.translateToItalian(key, this.quizId);
    },
isNotRemoveProducts(e){
      this.isNotRemoveSelectAllArray=e.isNotRemoveSelectAllArray
      this.notRemoveSelectAllArray=e.notRemoveSelectAllArray
    },
    copyLink(link) {
      if (link) {
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = link;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.$toasted.show(this.translateCustomText(`Link copied!`), {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    //  async copyLink(link){
    //     if(link){
    //        await navigator.clipboard.writeText(link);
    //     this.$toasted.show("Link Copied ", {
    //       theme: "toasted-primary",
    //       position: "bottom-center",
    //       duration: 2000,
    //     });
    //     }
    //   },

    openExternalLink(link) {
      window.open(link, "_blank");
    },
    openCurrentLink(link) {
      window.open(link, "_blank");
    },
    getScrollCss() {
      return `max-height:310px;height:100%;   overflow-y: scroll;
  scroll-behavior: smooth;`;
    },
    CloseErrorModal() {
      this.collectionLink = null;
      this.errorMsgType = null;
      this.isResponse = false;
      this.errorMsg = null;
    },
    resetData() {
      // this.products = [];
      this.title = null;
      this.description = null;
      this.selectedProducts = [];
    },
    openProduct(product) {
      if (product.detail_link) {
        window.open(product.detail_link, "_blank");
      }
    },
    async submitData() {
      if (this.isSubmitValid) {
        this.submitSpinner = true;
        try {
          let selectedProduct = [];
          if (!this.payload.save_all_status) {
            selectedProduct = this.selectedProducts.map((product_id) => {
              return { product_id: product_id };
            });
          } 
          let data = {
            title: this.title,
            description: this.description,
            // products: JSON.stringify(this.selectedProducts),
            add_products: selectedProduct,
            email: this.getSavedLeadID.email,
            api_token: this.apiToken,
            ...this.payload,
          };
           if(this.isNotRemoveSelectAllArray)
        {
       data.except_ids = this.notRemoveSelectAllArray
       }
          if (this.lastOption) {
            data["image"] = this.lastOption.selectedOption.image;
            // data['image'] =  'https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Desktop_01_1.png?v=1671799809https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Desktop_01_1.png?v=1671799809'
          }
          const response = await axios.post(
            `/api/custom/collection/filter/store`,
            data
          );

          if (response.status == 200 && response.data.status == "success") {
            this.isResponse = true;
            this.collectionLink = response.data.data;

            this.resetData();
          } else {
            if (
              response.status == 201 &&
              response.data.status == "affiliate_error"
            ) {
              this.isResponse = true;
              this.errorMsgType = response.data.status;
              this.errorMsg = response.data.data;
            } else {
              this.isResponse = true;
              this.errorMsgType = response.data.status;
              this.errorMsg = response.data.data;
            }
          }
        } catch (error) {
          if (error) {
            this.$toasted.show(
              this.translateCustomText(`An error has occurred`),
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
          }
          throw error;
        } finally {
          this.submitSpinner = false;
        }
      } else {
        this.title && this.selectedProducts.length;

        // this.alertMsg = "Per favore, fornisci i valori richiesti.";
        this.alertMsg = this.translateCustomText(`Please provide the required values`);

        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      }
    },
    restartQuiz() {
      window.location.href = this.$router.history.current.path;
    },
    restartQuizUpdate() {
      if (!localStorage.getItem("already_record_email")) {
        localStorage.setItem("already_record_email", this.getSavedLeadID.email);
        localStorage.setItem("already_record_status", true);
        this.$emit("changeToUpdateElth");
      }
    },

    getTitleStyles(property) {
      return (
        `color:${property?.titleColor || "#000"};opacity:${
          property?.titleTextOpacity || 100
        }%;` +
        " font-weight:" +
        property.fontWeight +
        "; font-style:" +
        property.fontStyle +
        ";text-decoration:" +
        property.textDecoration +
        ";font-size:" +
        property.fontSize +
        "px;font-family:" +
        property.fontFamily +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    toggleSingleProduct() {
      this.selectAllProducts = false;
    },
    async selectAllProductsEvent(e) {
      if (e) {
        try {
          this.selectedProducts = await this.getProductsTable.map((x) =>
            this.checkConvertIntegar(x.external_id)
          );

          // this.selectedProducts = [...this.selectedProducts , productsToAdd]
        } catch (error) {
          if (error) {
            this.$toasted.show(
              this.translateCustomText(`An error has occurred`),
              {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              }
            );
          }
        }
      } else {
        this.selectedProducts = [];
      }
    },
    generatProducts() {
      let products = [];
      for (let i = 0; i < 40; i++) {
        const id = `${i}`;
        const title = `Product-${i}`;
        const price = `${i * 100}`;
        const image = `https://images.unsplash.com/photo-1643185450492-6ba77dea00f6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80`;
        const vendor = `vendor-${i}`;
        products.push({ id, title, price, image, vendor });
        this.products = products;
      }
    },
    ImageSrc(img) {
      if (img) return img;
      return 'https://images.quizell.com/website/default_start_page.png';
    },
    getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    InputAdjust(e) {
      let Element = e.target;
      Element.rows = 2;
      const styles = window.getComputedStyle(Element);
      const paddingTop = parseInt(styles.paddingTop);
      const paddingBottom = parseInt(styles.paddingBottom);
      const padding = paddingTop + paddingBottom;
      const initialHeight = (parseInt(styles.height) - padding) / Element.rows;
      const scrollHeight = Element.scrollHeight - padding;
      const newRows = Math.ceil(scrollHeight / initialHeight);
      Element.rows = newRows - 1;
    },
    checkConvertIntegar(value) {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return parseInt(value, 10);
      }
    },
    openVendor(vendor) {
      if (vendor && !this.allowedQuizID.includes(this.quizId)) {
        let newVendor = vendor.replace(/ /g, "-");
        let url = `https://elth.co.uk/collections/${newVendor}`;
        window.open(url, "_blank");
      }
    },
    openElthInfo() {
      window.open(`https://elth-store.freshdesk.com/support/home`, "_blank");
    },
    async getTypesAndVendors() {
      if (this.products && this.products.length) {
        this.productVendors = [
          ...new Set(this.products.map((item) => item.vendor)),
        ];
        this.productType = [
          ...new Set(this.products.map((item) => item.product_type)),
        ];
      }
    },
    selectProduct(event, product) {
      this.selectAllProducts = false;
      let allowedTarget = ["DIV", "TD"];

      let productId = this.checkConvertIntegar(product.external_id);
      if (allowedTarget.includes(event.target.tagName)) {
        const index = this.selectedProducts.findIndex((id) => id == productId);
        if (index > -1) {
          this.selectedProducts.splice(index, 1);
        } else {
          this.selectedProducts.push(productId);
        }
      }
    },
    updateTexTDescription(data) {
      console.log(data.title)
      this.title = data.title;
      this.description = data.desc;
      this.isStep2 = true
    },
  },
  computed: {
    getOptions() {
      const selectedOptionIds = this.questionAnswer.flatMap((item) => {
        if (item.selectedOption) {
          if (Array.isArray(item.selectedOption)) {
            return item.selectedOption.map((option) => option.id);
          } else {
            return item.selectedOption.id;
          }
        }
        return [];
      });
      return selectedOptionIds;
    },
    getEnv() {
      return this.getEnvConst;
    },
    baldanClientQuizID() {
      return this.getEnv?.VUE_APP_ITALIAN_QUIZID??null;
    },
    allowedQuizID() {
      return [this.baldanClientQuizID, this.getEnv?.VUE_APP_ELTH_V3_QUIZID??null];
    },
    showResetFilterBtn() {
      return this.selectedVendor !== "" ||
        this.selectedType !== "" ||
        this.filter_text !== ""
        ? true
        : false;
    },
    getSavedUser() {
      return this.getSavedLeadID;
    },
    ...mapGetters(["getSavedLeadID", "getSelectedOptions", "getEnvConst"]),
    getProductsTable() {
      return this.products.filter((product) => {
        return (
          ((product.title != null &&
            product.title
              .toLowerCase()
              .includes(this.filter_text.trim().toLowerCase())) ||
            (product.vendor != null &&
              product.vendor != null &&
              product.vendor
                .toLowerCase()
                .includes(this.filter_text.trim().toLowerCase())) ||
            (product.sku != null &&
              product.sku
                .toLowerCase()
                .includes(this.filter_text.trim().toLowerCase()))) &&
          product.vendor != null &&
          product.vendor.includes(this.selectedVendor) &&
          product.product_type != null &&
          product.product_type.includes(this.selectedType)
        );
      });
    },
    lastOption() {
      let length = this.getSelectedOptions.questionAnswer.length;
      return this.getSelectedOptions.questionAnswer[length - 1];
    },
    isSubmitValid() {
      return this.title && this.selectedProducts.length;
    },
  },
};
</script>

<style scoped>
.input-element {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.CustomTextInputCollection {
  /* color: var(--inputColor) !important; */
  color: #503a3c !important;
  font-size: 18px !important;
}

.productDescription-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #503a3c !important;
  font-size: 18px !important;
  /* color: var(--inputTextColor); */
}

.productDescription-input::placeholder,
.input-element::placeholder {
  /* color: var(--inputPlaceholderColor); */
  color: #503a3c;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

.productDescription-input::placeholder {
  font-size: 18px !important;
}

/* .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before */
.SelectAllCheckboxResultPage
  >>> #SelectAllCheckboxResultPage.custom-control-input:checked
  ~ label::before {
  background: #9c4629 !important;
}

.ProductItemCheckbox >>> .custom-control-input:checked ~ label::before {
  background: #9c4629 !important;
}

.custom-select:focus {
  border-color: #9c4629;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #9c462979;
}

.ProductInfo {
  text-align: center !important;
}

.custom-select:focus option:checked {
  background-color: #9c4629 !important;
  color: rgb(255, 255, 255);
}

option:checked {
  background-color: #9c4629 !important;
  color: rgb(255, 255, 255);
}

option:hover {
  box-shadow: 0 0 10px 100px #9c4629 inset;
  background-color: #9c4629 !important;
  color: rgb(255, 255, 255);
}

.ProductTitle:hover {
  text-decoration: underline !important;
}

#ClientProductTable td {
  border-top: 1px solid #503a3c !important;
}

.ClientErrorModalContent {
  border-radius: 20px;
  text-align: left;
}

.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  color: #4d4950;
}

.TitleSub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4d4950bb;
}

.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}

.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}

.w-90 {
  width: 90%;
}

@media screen and (max-width: 750px) {
  .ProductInfo {
    margin-bottom: 0 !important;
  }

  .ResponsiveTable thead {
    display: none;
  }

  .ResponsiveTable,
  .ResponsiveTable tbody,
  .ResponsiveTable tr,
  .ResponsiveTable td {
    display: block;
    width: 100%;
  }

  .ResponsiveTable tr {
    margin-bottom: 15px;
  }

  #ClientProductTable td {
    border-top: none !important;
  }

  .ResponsiveTable td {
    text-align: center;
    /* padding-left: 50%; */
    position: relative;
  }

  .ResponsiveTable td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    text-align: left;
  }
}

.min-width-tr {
  min-width: 100px;
}

.stickyHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
}
.filled-button {
  background: #9c4629;
  border-radius: 30px;
  padding: 6px 24px;
  font-weight: 700;
  height: 43px;
  font-size: 14px;
  border: 1px solid #9c4629 !important;
  transition: 0.5s ease all;
}
.filled-button:hover {
  color: #9c4629 !important;
  border: 1px solid #9c4629 !important;
  background: transparent;
}
.outline-button:hover {
  background: #9c4629;
  color: #fff;
}
.outline-button {
  color: #9c4629;
  border: 1px solid #9c4629;
  padding: 6px 24px;
  font-weight: 700;
  height: 43px;
  font-size: 14px;
  background: transparent;
  transition: 0.5s ease all;
}
.filled-button:active {
  background-color: transparent !important;
  color: #9c4629;
  border: 1px solid #9c4629;
  box-shadow: inset 7px 6px 28px 1px rgba(156, 54, 41, 0.24) !important;
}
.outline-button:active {
  background-color: #9c4629 !important;
  box-shadow: inset 7px 6px 28px 1px rgba(0, 0, 0, 0.24) !important;
  /* Moving button 4px to y-axis */
}
@media only screen and (max-width: 550px) {
  .successImage {
    object-fit: contain;
  }
}

@media only screen and (min-width: 768px) {
  .w-md-75 {
    width: 75%;
  }
}
.elt-btn{
    /* background: #503A3C; */
    color:#fff;
   border-radius: 20px;
min-width: 168px;
color: #9C4629;
border:1px solid #9C4629;
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 500;

}

@media screen and (min-width: 991px)
{
  .mx-md-10, .mx-md-10 {
      /* margin:0 160px 0 160px!important; */
     padding:20px!important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .mx-md-10, .mx-md-10 {
    /* margin: 0 60px 0 60px !important; */
     padding:20px!important; 
  }
}
@media screen and (max-width: 768px)
{

  .mx-md-10, .mx-md-10 {
      /* margin:0px!important; */
     padding:20px !important; 
  }
}
</style>
